import React from "react"
import Blogs from "../components/Blog/Blogs"
import Layout from "../components/Base/Layout"
export default function(props) {
  return (
    <Layout>
      <Blogs />
    </Layout>
  )
}
